import { IconButton, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState, VFC } from "react";
import { useQuestsActions, useQuestsState } from "../../hooks/atoms/useQuests";
import { useAppReady } from "../../hooks/useAppReady";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { Tooltip } from "../Tooltip";
import { ActiveQuest, QuestConfig, QuestGroup } from "./quests.types";
import { getQuestConfigFromActiveQuest } from "./quests.util";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "fixed",
      display: "flex",
      flexDirection: "column",
      width: 360,
      padding: theme.spacing(1.25),
      borderRadius: theme.shape.borderRadius,
      bottom: -100,
      left: 0,
      right: 0,
      margin: "0 auto",
      zIndex: theme.zIndex.modal + 1,
      background: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      boxShadow: theme.shadows[9],
      transition: theme.transitions.create(["bottom"], {
        easing: theme.transitions.easing.easeOut,
        duration: 500,
        delay: 500,
      }),
    },
    active: {
      bottom: 20,
    },
    iconButton: {
      // This specificity is needed due to the first ssr
      "&.MuiButtonBase-root": {
        position: "absolute",
        top: 6,
        right: 6,
      },
    },
    icon: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    progressWrapper: {
      position: "relative",
      margin: theme.spacing(1, 0),
    },
  }),
  {
    classNamePrefix: "QuestProgressMeter",
  }
);

export type QuestProgressMeterJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestProgressMeterProps = {
  classes?: Partial<ClassNameMap<QuestProgressMeterJSSClassKey>>;
  className?: string;
};

export const QuestProgressMeter: VFC<QuestProgressMeterProps> = ({ className, classes: extClasses }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const timer = useRef<NodeJS.Timeout | undefined>(undefined);

  const appIsReady = useAppReady();

  const { activeQuest } = useQuestsState();
  const { setActiveQuest } = useQuestsActions();

  const [quest, setQuest] = useState<ActiveQuest<QuestGroup> | undefined>(undefined);
  const [progress, setProgress] = useState(0);
  const [active, setActive] = useState(false);

  const config = useMemo<QuestConfig<QuestGroup> | undefined>(
    () => (quest ? getQuestConfigFromActiveQuest(quest) : undefined),
    [quest]
  );

  const handleClose = useCallbackSafeRef(() => {
    setActiveQuest(undefined);
  });

  useEffect(() => {
    if (activeQuest === undefined) {
      // transition away
      timer.current = setTimeout(() => setQuest(activeQuest), 1000);
    } else {
      setQuest(activeQuest);
    }

    return () => {
      setQuest(activeQuest);
      clearTimeout(timer.current);
    };
  }, [activeQuest]);

  useEffect(() => {
    if (config && quest) {
      const activeIndex = config.steps.findIndex((s) => s.id === quest.step);
      setProgress(activeIndex >= 0 ? (activeIndex / config.steps.length) * 100 : 0);
    } else {
      setProgress(0);
    }
  }, [quest, config]);

  /**
   * Important: The component needs to be initially rendered with a active state of false. The
   * reason for this is that because it is rendered in context outside the next page tree, and therefor
   * is initially rendered on the server side. In order to apply the correct state on the initial
   * render we need it to hydrate with this effect.
   */
  useEffect(() => {
    setActive(!!activeQuest && appIsReady);
  }, [activeQuest, appIsReady]);

  return (
    <div className={clsx(classes.root, className, active && classes.active)}>
      <div>
        <Typography variant="body2">{config?.title}</Typography>
        <Tooltip title="Leave quest">
          <IconButton aria-label="close" size="small" className={classes.iconButton} onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.progressWrapper}>
        <LinearProgress variant="determinate" color="secondary" value={progress} />
      </div>
    </div>
  );
};
